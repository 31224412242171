var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading.fullscreen.lock",
              value: _vm.fullscreenLoading,
              expression: "fullscreenLoading",
              modifiers: { fullscreen: true, lock: true },
            },
          ],
          attrs: {
            modal: false,
            "close-on-click-modal": false,
            title: "调拨单号：" + _vm.form.bill_no,
            visible: _vm.dialogVisible,
            width: "1100px",
            center: "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "rods" },
            [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.form.allot_status_text == "已作废",
                      expression: "form.allot_status_text == '已作废'",
                    },
                  ],
                  staticStyle: {
                    display: "flex",
                    "justify-content": "center",
                    "align-items": "center",
                  },
                },
                [
                  _c("h3", [
                    _c("span", [_vm._v("作废人：")]),
                    _vm._v(" " + _vm._s(_vm.form.scrap_name) + " "),
                    _c("span", [_vm._v("作废时间：")]),
                    _vm._v(" " + _vm._s(_vm.form.scrap_time) + " "),
                  ]),
                ]
              ),
              _c(
                "el-form",
                {
                  ref: "form",
                  staticClass: "demo-form-inline",
                  attrs: { inline: true, "label-width": "150px" },
                  model: {
                    value: _vm.form,
                    callback: function ($$v) {
                      _vm.form = $$v
                    },
                    expression: "form",
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "出货仓库：" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "130px" },
                          attrs: {
                            disabled: _vm.type == 1,
                            placeholder: "出货仓库",
                          },
                          model: {
                            value: _vm.form.out_id,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "out_id", $$v)
                            },
                            expression: "form.out_id",
                          },
                        },
                        _vm._l(_vm.cklist, function (list) {
                          return _c("el-option", {
                            key: list.id,
                            attrs: { label: list.depot_name, value: list.id },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c("el-form-item", { attrs: { label: "申请时间：" } }, [
                    _c("span", [_vm._v(_vm._s(_vm.form.add_time))]),
                  ]),
                  _c("el-form-item", { attrs: { label: "申请人：" } }, [
                    _c("span", [_vm._v(_vm._s(_vm.form.add_name))]),
                  ]),
                  _vm.form.allot_status != 1
                    ? _c("el-form-item", { attrs: { label: "审核时间：" } }, [
                        _c("span", [_vm._v(_vm._s(_vm.form.check_time))]),
                      ])
                    : _vm._e(),
                  _c(
                    "el-form-item",
                    { attrs: { label: "入货仓库：" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "130px" },
                          attrs: {
                            disabled: _vm.type == 1,
                            placeholder: "入货仓库",
                          },
                          model: {
                            value: _vm.form.in_id,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "in_id", $$v)
                            },
                            expression: "form.in_id",
                          },
                        },
                        _vm._l(_vm.cklist, function (item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: { label: item.depot_name, value: item.id },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c("el-form-item", { attrs: { label: "调拨类型：" } }, [
                    _c("span", [_vm._v(_vm._s(_vm.form.allot_type_text))]),
                  ]),
                  _c("el-form-item", { attrs: { label: "审核人：" } }, [
                    _c("span", [_vm._v(_vm._s(_vm.form.check_name))]),
                  ]),
                ],
                1
              ),
              _c(
                "el-table",
                {
                  staticStyle: { width: "100%" },
                  attrs: {
                    stripe: "",
                    data: _vm.form.goods_data,
                    border: "",
                    "show-summary": "",
                    "summary-method": _vm.getSummaries,
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      type: "index",
                      index: _vm.indexMethod,
                      width: "50",
                      align: "center",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "类型",
                      width: "60",
                      prop: "goods_type",
                      align: "center",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var row = ref.row
                          return [
                            _vm._v(
                              " " +
                                _vm._s(
                                  row.goods_type == 1
                                    ? "常品"
                                    : row.goods_type == 2
                                    ? "赠品"
                                    : ""
                                ) +
                                " "
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "商品名称",
                      align: "center",
                      prop: "goods_name_print",
                      width: "250",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "商品规格",
                      align: "center",
                      prop: "goods_spec",
                      width: "150",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "单位",
                      align: "center",
                      prop: "goods_unit",
                      width: "110",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "申请数量",
                      align: "center",
                      prop: "apply_goods_num",
                      width: "80",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("el-input", {
                              attrs: { disabled: "" },
                              model: {
                                value: scope.row.apply_goods_num,
                                callback: function ($$v) {
                                  _vm.$set(scope.row, "apply_goods_num", $$v)
                                },
                                expression: "scope.row.apply_goods_num",
                              },
                            }),
                          ]
                        },
                      },
                    ]),
                  }),
                  (_vm.type == 1 && _vm.form.allot_status_text == "已审核") ||
                  (_vm.type != 1 && _vm.form.allot_status_text == "待审核")
                    ? _c("el-table-column", {
                        attrs: {
                          label: "实际数量",
                          align: "center",
                          prop: "goods_num",
                          width: "100",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c("el-input", {
                                    attrs: { disabled: _vm.numNoedit },
                                    on: {
                                      input: function ($event) {
                                        return _vm.moneyN(scope.row)
                                      },
                                    },
                                    model: {
                                      value: scope.row.goods_num,
                                      callback: function ($$v) {
                                        _vm.$set(scope.row, "goods_num", $$v)
                                      },
                                      expression: "scope.row.goods_num",
                                    },
                                  }),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          3123722250
                        ),
                      })
                    : _vm._e(),
                  _c("el-table-column", {
                    attrs: {
                      label: "单价",
                      align: "center",
                      prop: "goods_price",
                      width: "100",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("el-input", {
                              attrs: { disabled: _vm.form.allot_status != 1 },
                              on: {
                                input: function ($event) {
                                  return _vm.moneyN(scope.row)
                                },
                              },
                              model: {
                                value: scope.row.goods_price,
                                callback: function ($$v) {
                                  _vm.$set(scope.row, "goods_price", $$v)
                                },
                                expression: "scope.row.goods_price",
                              },
                            }),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "金额",
                      align: "center",
                      prop: "total_price",
                      width: "100",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-row",
                { staticClass: "beizhu" },
                [
                  _c("span", [_vm._v("备注：")]),
                  _c("el-input", {
                    staticStyle: { width: "220px" },
                    attrs: { type: "textarea", rows: 2, placeholder: "备注" },
                    model: {
                      value: _vm.form.remark,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "remark", $$v)
                      },
                      expression: "form.remark",
                    },
                  }),
                ],
                1
              ),
              _vm.form.old_code
                ? _c(
                    "div",
                    [
                      _c("span", [_vm._v("修改记录:")]),
                      _c(
                        "el-table",
                        {
                          staticStyle: {
                            width: "70%",
                            "margin-bottom": "10px",
                          },
                          attrs: {
                            stripe: "",
                            data: _vm.changeOld,
                            border: "",
                            height: "100",
                          },
                        },
                        [
                          _c("el-table-column", {
                            attrs: {
                              label: "原单号",
                              align: "center",
                              prop: "old_code",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (ref) {
                                    var row = ref.row
                                    return [
                                      _c(
                                        "span",
                                        {
                                          staticClass: "underline",
                                          on: {
                                            click: function ($event) {
                                              return _vm.handleCheckDiaoboOrder(
                                                row
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " + _vm._s(row.old_code) + " "
                                          ),
                                        ]
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              525226100
                            ),
                          }),
                          _c("el-table-column", {
                            attrs: {
                              label: "修改时间",
                              align: "center",
                              prop: "add_time",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              label: "修改人",
                              align: "center",
                              prop: "update_name",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "div",
                [
                  _c("span", [_vm._v("相关订单:")]),
                  _c(
                    "el-table",
                    {
                      staticStyle: { width: "70%" },
                      attrs: {
                        stripe: "",
                        data: _vm.form.orders,
                        border: "",
                        height: "200",
                      },
                    },
                    _vm._l(_vm.ordersd, function (trim) {
                      return _c("el-table-column", {
                        key: trim.order_id,
                        attrs: {
                          label: trim.label,
                          align: trim.center,
                          prop: trim.prop,
                          width: trim.width,
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (ref) {
                                var row = ref.row
                                return [
                                  trim.label == "单号"
                                    ? _c("div", [
                                        _c(
                                          "span",
                                          {
                                            staticClass: "underline",
                                            on: {
                                              click: function ($event) {
                                                return _vm.handleCheckOrder(row)
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              " " + _vm._s(row[trim.prop]) + " "
                                            ),
                                          ]
                                        ),
                                      ])
                                    : _c("div", [
                                        _vm._v(
                                          " " + _vm._s(row[trim.prop]) + " "
                                        ),
                                      ]),
                                ]
                              },
                            },
                          ],
                          null,
                          true
                        ),
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c("div", { staticClass: "flexe" }, [
                _c("span", [_vm._v(_vm._s(_vm.form.allot_status_text))]),
              ]),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _vm.isEdit && !_vm.showOld && _vm.type == 2
                ? _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.form.allot_status == 1,
                          expression: "form.allot_status == 1",
                        },
                      ],
                      attrs: { type: "primary" },
                      on: { click: _vm.shenhe },
                    },
                    [_vm._v(" 审 核 ")]
                  )
                : _vm._e(),
              _vm.isEdit && _vm.type == 2
                ? _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value:
                            _vm.form.allot_status == 1 &&
                            !_vm.showOld &&
                            _vm.cancel == 1,
                          expression:
                            "form.allot_status == 1 && !showOld && cancel == 1",
                        },
                      ],
                      on: { click: _vm.zuofei },
                    },
                    [_vm._v(" 作 废 ")]
                  )
                : _vm._e(),
              _vm.showOld
                ? _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.handleBack },
                    },
                    [_vm._v(" 返回 ")]
                  )
                : _vm._e(),
              _c("el-button", { on: { click: _vm.handlePrint } }, [
                _vm._v("打 印"),
              ]),
              _vm.form.allot_status_text == "待支付" && _vm.cancel == 1
                ? _c("el-button", { on: { click: _vm.handleDelete } }, [
                    _vm._v(" 作 废 "),
                  ])
                : _vm._e(),
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogVisible = false
                    },
                  },
                },
                [_vm._v("关 闭")]
              ),
            ],
            1
          ),
        ]
      ),
      _c("ruilang-display-dialog", {
        ref: "RuilangDisplayDialog",
        attrs: { bill_type: "DB", data_id: _vm.form.id, type: 1 },
      }),
      _c("advance-order", { ref: "advanceOrder" }),
      _c("sale-order-detail", { ref: "saleOrder" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }